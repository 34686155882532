@use '@/styles/utils/mixins.scss' as *;

.mapWraperContainer {
  display: flex;
  gap: 2.5rem;

  .searchSection {
    max-width: 30rem;
    width: 100%;
    padding: 1.875rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    height: 37.5rem;
    .searchBox {
      display: flex;
      border: 0.063rem solid $neutral06;
      border-radius: 1.25rem;
      overflow: hidden;
      padding: 0.25rem 1rem;
      padding-left: 2rem;
      align-items: center;
      background-color: $white;
      .searchBoxInput {
        padding: 0;
        border: 0;
        height: auto;
        min-height: auto;
        font-size: 1rem;
        line-height: normal;
        color: $neutral08;
        font-weight: 500;
        outline: 0;
        &::placeholder {
          color: $neutral06;
          opacity: 1;
        }
        &:focus {
          box-shadow: none;
        }
      }
      .searchBtn {
        padding: 1rem;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        height: 100%;
        border-radius: 1.25rem;
        transition: all 0.2s ease-in-out;

        &:focus {
          box-shadow: none;
        }
        &:hover,
        &:focus,
        &:active {
          background: $neutral01 !important;
        }
      }
    }
    @include tab() {
      padding: 0;
    }
  }
  .mapSection {
    flex: 1 1 auto;
    height: 37.5rem;
    border-radius: 1.25rem;
    overflow: hidden;
    @include tab() {
      display: none;
    }
  }
  .locationLists {
    display: flex;
    gap: 1.25rem;
    flex: 1 1 auto;
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: 0.25rem;

    .locationCard {
      border-radius: 1.25rem;
      padding: 1.875rem;
      background-color: $white;
      border: 0.063rem solid $primary;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        border-color: $primaryAlt;
      }
      &Header {
        margin-bottom: 1.25rem;
        :global {
          .headingElement {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .titleCaption {
              margin-bottom: 0;
            }
          }
        }
      }
      &Body {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        color: $neutral08;
        a {
          color: $neutral08;
          &:hover,
          &:focus,
          &:active {
            color: $primary;
          }
        }
        p {
          margin-bottom: 0;
        }
        address {
          font-style: normal;
        }
      }
    }
  }
}
